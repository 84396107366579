import { createContext, ReactNode, useContext, useState } from "react";

type CarCheckoutContextType = {
  driverRequest: CarCheckoutTravelerRequestType;
  travelerInfoReset: CarDriverslistTempType;
  priceDetail: FareSummaryResType;
  showBottom: boolean;
  carbooked: CarProceedBookResponseType | null;
  showVoucher: boolean;
  isMobileSC: boolean;
  driversInformation: CarDriverslistTempType[];
  driverValidation: CarTravelerValidation;
  couponCode: ApplyCouponCodeType | null;
  userTravelerId: string;
  extraSelected: CarExtrasCheckboxType[];
  carCashAmount: PaymentMethodCashAmountCars | null;
  setExtraSelected: (data: CarExtrasCheckboxType[]) => void;
  setUserTravelerId: (value: string) => void;
  setCouponCode: (value: ApplyCouponCodeType | null) => void;
  setDriverValidation: (value: CarTravelerValidation) => void;
  setTitleValidation: (value: string | null) => void;
  setFirstNameValidation: (value: string | null) => void;
  setLastNameValidation: (value: string | null) => void;
  setEmailValidation: (value: string | null) => void;
  setCountryCodeValidation: (value: string | null) => void;
  setPhoneValidation: (value: string | null) => void;
  setAgeValidation: (value: string | null) => void;
  setDriversInformation: (drivers: CarDriverslistTempType[]) => void;
  setIsMobileSc: (flag: boolean) => void;
  setShowVoucher: (flag: boolean) => void;
  setCarBooked: (data: CarProceedBookResponseType) => void;
  setShowBottom: (flag: boolean) => void;
  setPriceDetail: (data: FareSummaryResType) => void;
  resetDriverRequest: () => void;
  resettravelerInfo: () => void;
  setCurrentDriver: (data: CarCheckoutTravelerRequestType) => void;
  setAgeReq: (value: string) => void;
  setIsdCodeReq: (value: number) => void;
  setTravAddress1Req: (value: string) => void;
  setTravCityReq: (value: string) => void;
  setTravCountryCodeReq: (value: number) => void;
  SetTravDobReq: (value: string) => void;
  setTravEmailReq: (value: string) => void;
  setTravFirstNameReq: (value: string) => void;
  setTravGenderReq: (value: string) => void;
  setTravGenderTitleReq: (value: string) => void;
  setTravLastNameReq: (value: string) => void;
  setTravMiddleNameReq: (value: string) => void;
  setTravNationalityReq: (value: string) => void;
  setTravPassportExpiredReq: (value: string) => void;
  setravPassportNoReq: (value: string) => void;
  setTravPhoneReq: (value: string) => void;
  setTravPostalCodeReq: (value: string) => void;
  setTravStateReq: (value: string) => void;
  setTravTitleReq: (value: string) => void;
  setUsrTypeReq: (value: number) => void;
  setCashCarAmount: (data: PaymentMethodCashAmountCars | null) => void;
};

const CarCheckoutContextDefaultValues: CarCheckoutContextType = {
  driverRequest: {
    age: "",
    isd_code: 0,
    trav_address1: "",
    trav_city: "",
    trav_country_code: 0,
    trav_dob: "",
    trav_email: "",
    trav_first_name: "",
    trav_gender: "",
    trav_last_name: "",
    trav_middle_name: "",
    trav_nationality: "",
    trav_passport_expired: "",
    trav_passport_no: "",
    trav_phone: "",
    trav_postal_code: "",
    trav_state: "",
    trav_title: "",
    usr_type: 2,
    nationality_id: 0,
  },
  travelerInfoReset: {
    address1: "",
    address2: "",
    age: "",
    city: "",
    country: "",
    code_countryID: "",
    date_to_birth: "",
    email: "",
    firstName: "",
    gender: "",
    lastName: "",
    middle_name: "",
    nationality: "",
    passport_expiry_date: "",
    passport_no: "",
    phone: "",
    redress_no: "",
    trav_postal_code: "",
    trav_title: "",
    dayDateBird: 0,
    monthDateBird: 0,
    yearDateBirds: 0,
    daypassportExpiryDate: 0,
    monthPassportExpiryDate: 0,
    yearPassportExpiryDate: 0,
    passportID: "",
    redressNumber: "",
    position: "",
    trav_id: "",
    id: "",
    loyaltyresult: [],
  },
  priceDetail: {
    totalpayableamt: "",
    taxes: "",
    baseprice: "",
    perday_basefare: "",
    perday_taxes: "",
    days: 0,
    currency: "",
    supplier_name: "",
    price_type: "",
    roe_rate: 0,
    markup_add: "",
    pricingRuleObj: "",
    bfr_mrkup_USD_tt: "",
    currency_symbol: "",
  },
  showBottom: true,
  carbooked: null,
  showVoucher: false,
  isMobileSC: false,
  driversInformation: [],

  driverValidation: {
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone: "",
    age: "",
  },
  couponCode: null,
  userTravelerId: "",
  extraSelected: [],
  carCashAmount: null,
  setExtraSelected: () => {},
  setUserTravelerId: () => {},
  setCouponCode: () => {},
  setDriverValidation: () => {},
  setTitleValidation: () => {},
  setFirstNameValidation: () => {},
  setLastNameValidation: () => {},
  setEmailValidation: () => {},
  setCountryCodeValidation: () => {},
  setPhoneValidation: () => {},
  setAgeValidation: () => {},

  setDriversInformation: () => {},
  setIsMobileSc: () => {},
  setShowVoucher: () => {},
  setCarBooked: () => {},
  setShowBottom: () => {},
  setPriceDetail: () => {},
  resetDriverRequest: () => {},
  resettravelerInfo: () => {},
  setCurrentDriver: () => {},
  setAgeReq: () => {},
  setIsdCodeReq: () => {},
  setTravAddress1Req: () => {},
  setTravCityReq: () => {},
  setTravCountryCodeReq: () => {},
  SetTravDobReq: () => {},
  setTravEmailReq: () => {},
  setTravFirstNameReq: () => {},
  setTravGenderReq: () => {},
  setTravGenderTitleReq: () => {},
  setTravLastNameReq: () => {},
  setTravMiddleNameReq: () => {},
  setTravNationalityReq: () => {},
  setTravPassportExpiredReq: () => {},
  setravPassportNoReq: () => {},
  setTravPhoneReq: () => {},
  setTravPostalCodeReq: () => {},
  setTravStateReq: () => {},
  setTravTitleReq: () => {},
  setUsrTypeReq: () => {},
  setCashCarAmount: () => {},
};

const CarCheckoutContext = createContext<CarCheckoutContextType>(
  CarCheckoutContextDefaultValues
);

export function useCarCheckout() {
  return useContext(CarCheckoutContext);
}

type Props = {
  children: ReactNode;
};

export function CarCheckoutProvider({ children }: Props) {
  const [driverRequest, setDriverRequest] =
    useState<CarCheckoutTravelerRequestType>(
      CarCheckoutContextDefaultValues.driverRequest
    );

  const [travelerInfoReset, setTravelerInfoReset] =
    useState<CarDriverslistTempType>(
      CarCheckoutContextDefaultValues.travelerInfoReset
    );

  const [priceDetail, setPriceDetailData] = useState<FareSummaryResType>(
    CarCheckoutContextDefaultValues.priceDetail
  );

  const [showBottom, setShowBottomSection] = useState(true);

  const [carbooked, setCarBookedData] = useState(
    CarCheckoutContextDefaultValues.carbooked
  );

  const [showVoucher, setShowVoucherModal] = useState(
    CarCheckoutContextDefaultValues.showVoucher
  );

  const [isMobileSC, setIsMobileScreen] = useState(
    CarCheckoutContextDefaultValues.isMobileSC
  );

  const [driversInformation, setDriversInformationData] = useState<
    CarDriverslistTempType[]
  >(CarCheckoutContextDefaultValues.driversInformation);

  const [driverValidation, setDriverValidationData] =
    useState<CarTravelerValidation>(
      CarCheckoutContextDefaultValues.driverValidation
    );

  const [couponCode, setCouponCodeData] = useState(
    CarCheckoutContextDefaultValues.couponCode
  );

  const [userTravelerId, setUserTravelerValId] = useState<string>(
    CarCheckoutContextDefaultValues.userTravelerId
  );

  const [extraSelected, setExtraSelectedData] = useState<
    CarExtrasCheckboxType[]
  >(CarCheckoutContextDefaultValues.extraSelected);

  const [carCashAmount, setCashCarAmount] =
    useState<PaymentMethodCashAmountCars | null>(
      CarCheckoutContextDefaultValues?.carCashAmount
    );

  const setCouponCode = (data: ApplyCouponCodeType | null) => {
    setCouponCodeData(data);
  };

  const setCarBooked = (data: CarProceedBookResponseType) => {
    setCarBookedData(data);
  };

  const setShowVoucher = (flag: boolean) => {
    setShowVoucherModal(flag);
  };

  const setIsMobileSc = (flag: boolean) => {
    setIsMobileScreen(flag);
  };

  const setShowBottom = (flag: boolean) => {
    setShowBottomSection(flag);
  };
  const setPriceDetail = (data: FareSummaryResType) => {
    setPriceDetailData(data);
  };

  const resetDriverRequest = () => {
    setDriverRequest(CarCheckoutContextDefaultValues.driverRequest);
  };

  const resettravelerInfo = () => {
    setTravelerInfoReset(CarCheckoutContextDefaultValues.travelerInfoReset);
  };

  const setCurrentDriver = (data: CarCheckoutTravelerRequestType) => {
    setDriverRequest(data);
  };

  const setDriversInformation = (data: CarDriverslistTempType[]) => {
    setDriversInformationData(data);
  };
  const setAgeReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      age: value,
    });
  };
  const setIsdCodeReq = (value: number) => {
    setDriverRequest({
      ...driverRequest,
      isd_code: value,
      trav_country_code: value,
    });
  };
  const setTravAddress1Req = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_address1: value,
    });
  };
  const setTravCityReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_city: value,
    });
  };
  const setTravCountryCodeReq = (value: number) => {
    setDriverRequest({
      ...driverRequest,
      trav_country_code: value,
    });
  };
  const SetTravDobReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_dob: value,
    });
  };

  const setTravEmailReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_email: value,
    });
  };

  const setTravFirstNameReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_first_name: value,
    });
  };
  const setTravGenderReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_gender: value,
    });
  };
  const setTravGenderTitleReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_gender: value,
      trav_title: value,
    });
  };

  const setTravLastNameReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_last_name: value,
      trav_middle_name: value,
    });
  };
  const setTravMiddleNameReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_middle_name: value,
      trav_last_name: value,
    });
  };
  const setTravNationalityReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_nationality: value,
    });
  };
  const setTravPassportExpiredReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_passport_expired: value,
    });
  };
  const setravPassportNoReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_passport_no: value,
    });
  };
  const setTravPhoneReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_phone: value,
    });
  };
  const setTravPostalCodeReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_postal_code: value,
    });
  };
  const setTravStateReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_state: value,
    });
  };
  const setTravTitleReq = (value: string) => {
    setDriverRequest({
      ...driverRequest,
      trav_title: value,
    });
  };
  const setUsrTypeReq = (value: number) => {
    setDriverRequest({
      ...driverRequest,
      usr_type: value,
    });
  };

  const setDriverValidation = (value: CarTravelerValidation) => {
    setDriverValidationData(value);
  };

  const setTitleValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      title: value,
    });
  };
  const setFirstNameValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      first_name: value,
    });
  };
  const setLastNameValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      last_name: value,
    });
  };
  const setEmailValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      email: value,
    });
  };
  const setCountryCodeValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      country_code: value,
    });
  };
  const setPhoneValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      phone: value,
    });
  };
  const setAgeValidation = (value: string | null) => {
    setDriverValidationData({
      ...driverValidation,
      age: value,
    });
  };

  const setUserTravelerId = (value: string) => {
    setUserTravelerValId(value);
  };

  const setExtraSelected = (data: CarExtrasCheckboxType[]) => {
    setExtraSelectedData(data);
  };

  const value = {
    driverRequest,
    travelerInfoReset,
    priceDetail,
    showBottom,
    carbooked,
    showVoucher,
    isMobileSC,
    driversInformation,
    driverValidation,
    couponCode,
    userTravelerId,
    extraSelected,
    carCashAmount,
    setExtraSelected,
    setUserTravelerId,
    setCouponCode,
    setDriverValidation,
    setTitleValidation,
    setFirstNameValidation,
    setLastNameValidation,
    setEmailValidation,
    setCountryCodeValidation,
    setPhoneValidation,
    setAgeValidation,
    setDriversInformation,
    setIsMobileSc,
    setShowVoucher,
    setCarBooked,
    setShowBottom,
    setPriceDetail,
    resetDriverRequest,
    resettravelerInfo,
    setCurrentDriver,
    setAgeReq,
    setIsdCodeReq,
    setTravAddress1Req,
    setTravCityReq,
    setTravCountryCodeReq,
    SetTravDobReq,
    setTravEmailReq,
    setTravFirstNameReq,
    setTravGenderReq,
    setTravGenderTitleReq,
    setTravLastNameReq,
    setTravMiddleNameReq,
    setTravNationalityReq,
    setTravPassportExpiredReq,
    setravPassportNoReq,
    setTravPhoneReq,
    setTravPostalCodeReq,
    setTravStateReq,
    setTravTitleReq,
    setUsrTypeReq,
    setCashCarAmount,
  };

  CarCheckoutContext.displayName = "CarCheckoutContext";

  return (
    <CarCheckoutContext.Provider value={value}>
      {children}
    </CarCheckoutContext.Provider>
  );
}
