//****** UAT section *****/
import ctc from "./uat/ctc.json";

//****** TEST section *****/
// import localhost from "./localhost.json";
import ctc2 from "./test/ctc2.json";
import hotelsv2 from "./test/hotelsv2.json";
import voyvio from "./test/voyvio.json";

//****** PREPROD section *****/
import inspiraholidays from "./preprod/inspiraholidays.json";
import destination from "./preprod/destination.json";

//****** PROD section *****/
import cleartravelservices from "./prod/cleartravelservices.json";
import happi from "./prod/happi.json";
import loyalty from "./prod/loyalty.json";
import edge from "./prod/edge.json";
import happitravel from "./prod/happitravel.json";
import travelijf from "./prod/travelijf.json";
import wgftravel from "./prod/wgftravel.json";
import destinationsaver from "./prod/destinationsaver.json";
import hoteltraveldirect from "./prod/hoteltraveldirect.json";
import loyaltycoverage360 from "./prod/loyaltycoverage360.json";
import bookingftt from "./prod/bookingftt.json";
import p1trips from "./prod/p1trips.json";
import hotelscondotc from "./prod/hotelscondotc.json";
import traveliesforg from "./prod/traveliesforg.json";
import myvipsaver from "./prod/myvipsaver.json";
import hotelsinspiravacations from "./prod/hotelsinspiravacations.json";
import hotelsgunhildtravel from "./prod/hotelsgunhildtravel.json";
import hotelsnetcoo from "./prod/hotelsnetcoo.json";
import hotelsresortescapes from "./prod/hotelsresortescapes.json";
import oneinspiraholidays from "./prod/oneinspiraholidays.json";
import squirrelsavings from "./prod/squirrelsavings.json";
import xtaoexpeditions from "./prod/xtaoexpeditions.json";
import premiertravelsavings from "./prod/premiertravelsavings.json";
import travelwithsky from "./prod/travelwithsky.json";
import sportsntravel from "./prod/sportsntravel.json";
import naarclubdeviajes from "./prod/naarclubdeviajes.json";
import travelclubsdirect from "./prod/travelclubsdirect.json";
import lifestyletravelpros from "./prod/lifestyletravelpros.json";
import royaltytravel from "./prod/royaltytravel.json";
import electi from "./prod/electi.json";
import membersprestigetravel from "./prod/membersprestigetravel.json";
import giftcardprestigetravel from "./prod/giftcardprestigetravel.json";
import ibhinspiravacations from "./prod/ibhinspiravacations.json";
import xtreamtravellifestyle from "./prod/xtreamtravellifestyle.json";
import prestigetravel from "./prod/prestigetravel.json";
import travelreview from "./prod/travelreview.json";
import voyages from "./prod/voyages.json";
import travelotter from "./prod/travelotter.json";
import holidaynetwork from "./prod/holidaynetwork.json";
import indiasite from "./prod/indiasite.json";
import roomsandmore from "./prod/roomsandmore.json";
import roomsandmoreinspira from "./prod/roomsandmoreinspira.json";
import travelevoplus from "./prod/travelevoplus.json";
import membersendlessluxury from "./prod/membersendlessluxury.json";
import tensech from "./prod/tensech.json";
import tensechBalancer from "./prod/tensechbalalcer2.json";
import travelPrestigetravelers from "./prod/travelprestigetravelers.json";
import travelprestige from "./prod/travelprestige.json";
import hawaiiislandexperiences from "./prod/hawaiiislandexperiences.json";
import hawaiiis from "./prod/hawaiiis.json";
import memberssurge365 from "./prod/memberssurge365.json";
import rcihotelsinspiratest from "./prod/rcihotelsinspiratest.json";
import viajeschubbmasterrewards from "./prod/viajeschubbmasterrewards.json";
import guest2myvortex365 from "./prod/guest2myvortex365.json";
import clubroveinspiratest from "./prod/clubroveinspiratest.json";
import rossgoldinginspiratest from "./prod/rossgoldinginspiratest.json";
import swychtravel from "./prod/swychtravel.json";
import visa from "./prod/visa.json";
import membersessencetravelclub from "./prod/membersessencetravelclub.json";
import traveltazew from "./prod/traveltazew.json";
import membersprestigetravel365 from "./prod/membersprestigetravel365.json";
import memberskarismadestinations from "./prod/memberskarismadestinations.json";
import traveltramerigo from "./prod/traveltramerigo.json";
import royalinspiravacations from "./prod/royalinspiravacations.json";
import verizon from "./prod/verizon.json";
import travelclubroveplus from "./prod/travelclubroveplus.json";
import creditstravelleisureclub from "./prod/creditstravelleisureclub.json";
import traveleisureclub from "./prod/traveleisureclub.json";
import wwwtraveleisureclub from "./prod/wwwtraveleisureclub.json";
import travelspassportclub from "./prod/travelspassportclub.json";
import travelbeachsamiu from "./prod/travelbeachsamui.json";
import travelroomsandmore from "./prod/travelroomsandmore.json";
import zuperhotels from "./prod/zuperhotels.json";
import travelamericansavingsnetwork from "./prod/travelamericansavingsnetwork.json";
import travelgowithnext from "./prod/travelgowithnext.json";
import traveltaitamallure from "./prod/traveltaitamallure.json";
import sportsntravelwww from "./prod/sportsntravelwww.json";
import savingsendlessluxury from "./prod/savingsendlessluxury.json";
import traveltraveladvisorclub from "./prod/traveltraveladvisorclub.json";
import travelglobaltravelstore from "./prod/travelglobaltravelstore.json";

const domains: DomainsConfigType[] = [
  // localhost,
  ctc,
  cleartravelservices,
  destination,
  happi,
  loyalty,
  edge,
  happitravel,
  travelijf,
  wgftravel,
  destinationsaver,
  hoteltraveldirect,
  loyaltycoverage360,
  bookingftt,
  p1trips,
  hotelscondotc,
  traveliesforg,
  myvipsaver,
  hotelsinspiravacations,
  hotelsgunhildtravel,
  hotelsnetcoo,
  hotelsresortescapes,
  oneinspiraholidays,
  squirrelsavings,
  xtaoexpeditions,
  premiertravelsavings,
  travelwithsky,
  sportsntravel,
  naarclubdeviajes,
  travelclubsdirect,
  lifestyletravelpros,
  royaltytravel,
  electi,
  membersprestigetravel,
  giftcardprestigetravel,
  ibhinspiravacations,
  xtreamtravellifestyle,
  prestigetravel,
  travelreview,
  voyages,
  travelotter,
  holidaynetwork,
  indiasite,
  roomsandmore,
  roomsandmoreinspira,
  travelevoplus,
  membersendlessluxury,
  tensech,
  tensechBalancer,
  travelPrestigetravelers,
  travelprestige,
  hawaiiislandexperiences,
  hawaiiis,
  memberssurge365,
  rcihotelsinspiratest,
  viajeschubbmasterrewards,
  guest2myvortex365,
  clubroveinspiratest,
  rossgoldinginspiratest,
  swychtravel,
  visa,
  membersessencetravelclub,
  traveltazew,
  membersprestigetravel365,
  memberskarismadestinations,
  traveltramerigo,
  royalinspiravacations,
  verizon,
  travelclubroveplus,
  creditstravelleisureclub,
  traveleisureclub,
  travelspassportclub,
  wwwtraveleisureclub,
  travelbeachsamiu,
  travelroomsandmore,
  zuperhotels,
  travelamericansavingsnetwork,
  travelgowithnext,
  traveltaitamallure,
  sportsntravelwww,
  savingsendlessluxury,
  traveltraveladvisorclub,
  travelglobaltravelstore,
];

const domainsTest: DomainsConfigType[] = [
  ctc2,
  hotelsv2,
  inspiraholidays,
  voyvio,
];

export default domains.concat(domainsTest);
